body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
}

div.events-table table th, div.rider-table table th {
  border: none;
}

div.events-table table tr:first-child th, div.rider-table table tr:first-child th {
  font-size: x-large;
}

tr.rider-row td {
  padding-top: 25px;
  padding-bottom: 0px;
}

tr.field-row td {
  padding: 3px;
}
tr.field-row button {
  width: 100%;
}

div.rider-table table td,
div.events-table table td {
  border: #DDD 1px solid;
}

div.rider-table table td, div.events-table table td {
  cursor: pointer;
}

button.close {
  font-size: 2.5rem;
  color: black;
}

.rider-table {
  overflow-x: scroll;
}

.section {
  padding: 1rem 1.5rem;
}

.checkout-wrapper {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

header {
  background: #333;
}

td.fs-2 {
  font-size: 1.3rem;
}

.fs-3 {
  font-size: 1.2rem;
}
